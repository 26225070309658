import { ICustomViewableEntity } from "@/lib/interfaces/custom-viewable-entity.interface";
import { TicketStatusEnum } from "@/lib/enum/ticket-status.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { ICreateDto } from "@/lib/utility/data/create-dto.interface";
import { formatHoursAndMinutes, formatYearsMonthDay } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import ticketService from "@/services/mrfiktiv/services/ticketService";
import {
  MrfiktivCreateTicketDtoGen,
  MrfiktivTicketViewModelGen,
  MrfiktivTimestampGen,
  MrfiktivUpdateTicketDtoGen,
  MrfiktivReferenceGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ticketStateMap } from "@/store/mapper/ticket-state.map";
import { ProjectDataAccessLayer } from "@/store/modules/access-layers/project.access-layer";
import { TicketDataAccessLayer } from "@/store/modules/access-layers/ticket.access-layer";
import { ActivityLogModule, ActivityTypeEnum } from "@/store/modules/activity-log.store";
import { FleetAggregationModule } from "@/store/modules/fleet-aggregation.store";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import Vue from "vue";
import { CustomFieldValue, ICustomFieldValue } from "./custom-field-value.entity";
import { IReference, Reference } from "./reference.entity";
import { IShortUser, ShortUser } from "./short-user.entity";
import { VehicleReference } from "./vehicle-reference.entity";

@IsFilterable
class TicketBase
  implements
    MrfiktivTicketViewModelGen,
    ICreateDto<ITicket>,
    ICustomViewableEntity<ITicket, MrfiktivUpdateTicketDtoGen> {
  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.ticket.id",
    config: {
      itemCallback: () => TicketDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-ticket"
    }
  })
  id = "";

  /** identifier of partner that manages ticket */
  partnerId = "";

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.ticket.userId",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  userId = "";

  /** The user */
  user?: IShortUser;

  /** timestamp of the ticket */
  timestamp: MrfiktivTimestampGen = { created: "", lastModified: "" };

  /** unique number of the ticket */

  @FilterConfig({
    type: FilterTypes.NUMBER,
    displayName: "objects.ticket.number",
    width: "100"
  })
  number = -1;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.ticket.projectId",
    config: {
      items: ProjectDataAccessLayer.entities,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-project"
    }
  })
  projectId?: string;

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.ticket.title",
    width: "350"
  })
  title = "";

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.ticket.body"
  })
  body = "";

  @FilterConfig({
    displayName: "objects.ticket.tags",
    type: FilterTypes.STRING
  })
  tags?: string[];

  @FilterConfig({
    displayName: "objects.ticket.assignees",
    type: FilterTypes.OBJECT_ID,
    width: "120",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  assignees: string[];

  assigneesDetails?: IShortUser[];

  @FilterConfig({
    displayName: "objects.ticket.state",
    type: FilterTypes.ENUM,
    config: { items: Object.values(TicketStatusEnum) },
    width: "85"
  })
  state?: TicketStatusEnum;

  /**
   * when the ticket was closed
   * @format date-time
   */
  @FilterConfig({
    displayName: "objects.ticket.closedAt",
    type: FilterTypes.DATE
  })
  closedAt?: string;

  /** user id that closed the ticket */
  @FilterConfig({
    displayName: "objects.ticket.closedBy",
    type: FilterTypes.OBJECT_ID,
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  closedBy?: string;

  /** user details that closed the ticket */
  closedByDetails?: IShortUser;

  /** values of the custom fields */
  values: ICustomFieldValue[] = [];

  /** The references of the ticket */

  @FilterConfig({
    type: VehicleReference
  })
  refs?: IReference[];

  dueDate?: string;
  dueTime?: string = "00:00";

  loading = false;

  @FilterConfig({
    displayName: "objects.ticket.due",
    type: FilterTypes.DATE
  })
  get due(): string | undefined {
    if (this.dueDate) {
      const d = new Date(this.dueDate);

      // If no due time set it to "00:00" local time
      if (!this.dueTime) {
        this.dueTime = "00:00";
      }
      const [hours, minutes] = this.dueTime.split(":");
      d.setUTCHours(+hours, +minutes);
      return d.toISOString();
    }

    return undefined;
  }

  set due(due: string | undefined) {
    if (!due) {
      return;
    }

    const d = new Date(due);
    this.dueDate = formatYearsMonthDay(d);
    this.dueTime = formatHoursAndMinutes(d);
  }

  get dueDateTime() {
    if (this.dueDate) {
      return new Date(this.dueDate).getTime();
    }

    return undefined;
  }

  get baseUpdateDto() {
    const dto: MrfiktivUpdateTicketDtoGen = {
      projectId: this.projectId,
      tags: this.tags
    };

    if (this.due) {
      dto.due = this.due;
    } else {
      dto.due = "";
    }

    return dto;
  }

  get updateDto(): MrfiktivUpdateTicketDtoGen {
    const dto: MrfiktivUpdateTicketDtoGen = {
      body: this.body,
      projectId: this.projectId,
      tags: this.tags,
      title: this.title,
      values: this.values.map(v => {
        return { id: v.id, value: v.value };
      }),
      assignees: this.assignees,
      refs: this.refs,
      state: this.state
    };

    if (this.due) {
      dto.due = this.due;
    }

    return dto;
  }

  get stateColor() {
    return ticketStateMap.get(this.state || TicketStatusEnum.DRAFT);
  }

  /**
   * Indicates if the given ticket has a due date
   */
  get isDue() {
    return this.due && this.state === TicketStatusEnum.OPEN;
  }

  /**
   * Indicates if the given ticket is overdue
   */
  get isOverdue() {
    if (this.due && this.state === TicketStatusEnum.OPEN) {
      return new Date(this.due) < new Date();
    }

    return false;
  }

  get titleReadable() {
    return `#${this.number} ${this.title}`;
  }

  constructor(ticket?: Partial<TicketBase | MrfiktivTicketViewModelGen>) {
    this.id = ticket?.id || "";
    this.partnerId = ticket?.partnerId || "";
    this.userId = ticket?.userId || "";
    this.user = ticket?.user || this.userId ? PartnerUserModule.maps.id.get(this.userId)[0] : undefined;
    this.timestamp = ticket?.timestamp || { created: "", lastModified: "" };
    this.number = ticket?.number as number;
    this.projectId = ticket?.projectId;
    this.title = ticket?.title || "";
    this.body = ticket?.body || "";
    this.tags = ticket?.tags || [];
    this.assignees = ticket?.assignees || [];
    this.assigneesDetails = (ticket?.assigneesDetails || []).map(u => new ShortUser(u));
    this.state = (ticket?.state as TicketStatusEnum) || TicketStatusEnum.OPEN;
    this.closedAt = ticket?.closedAt;
    this.closedBy = ticket?.closedBy;
    this.closedByDetails = ticket?.closedByDetails ? new ShortUser(ticket.closedByDetails) : undefined;
    this.values = (ticket?.values || []).map(v => new CustomFieldValue(v));
    this.refs = (ticket?.refs ?? [])?.map((r: IReference | MrfiktivReferenceGen) => new Reference(r));
    this.due = ticket?.due;
  }

  async fetch(): Promise<this> {
    try {
      let fetched: MrfiktivTicketViewModelGen;

      if (this.id) {
        fetched = await ticketService.getOne(this.partnerId, this.id);
      } else if (this.number) {
        fetched = await ticketService.getOneByNumber(this.partnerId, this.number);
      } else {
        throw new Error("No id or number provided");
      }

      this.map(fetched);
      TicketDataAccessLayer.set(this);
      FleetAggregationModule.replaceTicket(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  map(ticket?: MrfiktivTicketViewModelGen) {
    if (!ticket) return;

    this.id = ticket.id;
    this.partnerId = ticket.partnerId;
    this.userId = ticket.userId;
    this.user = ticket.user || this.userId ? PartnerUserModule.maps.id.get(this.userId)[0] : undefined;
    this.timestamp = ticket.timestamp;
    this.number = ticket.number;
    this.projectId = ticket.projectId;
    this.title = ticket.title ?? "";
    this.body = ticket.body ?? "";
    this.tags = ticket.tags;
    this.assignees.splice(0, this.assignees.length, ...(ticket.assignees ?? []));
    this.assigneesDetails = (ticket.assigneesDetails || []).map(u => new ShortUser(u));
    this.state = ticket.state as TicketStatusEnum;
    this.closedAt = ticket.closedAt;
    this.closedBy = ticket.closedBy;
    this.closedByDetails = ticket.closedByDetails ? new ShortUser(ticket.closedByDetails) : undefined;
    this.values = (ticket?.values || []).map(v => new CustomFieldValue(v));
    this.refs = ticket?.refs?.map((r: MrfiktivReferenceGen) => new Reference(r)) || [];
    this.due = ticket.due;
  }

  async create() {
    try {
      const data: MrfiktivCreateTicketDtoGen = {
        title: this.title,
        body: this.body,
        projectId: this.projectId ? this.projectId : undefined,
        tags: this.tags,
        values: this.values,
        assignees: this.assignees,
        refs: this.refs,
        state: this.state,
        due: this.due
      };
      const res = await ticketService.create(this.partnerId, data);

      this.map(res);

      if (this.assignees?.length) this.createAssigneeActivity(ActivityTypeEnum.CREATE_ASSIGNEE, this.assignees);

      TicketDataAccessLayer.set(this);
      FleetAggregationModule.parseTickets([this]);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  async delete(silent?: boolean) {
    try {
      const res = await ticketService.delete(this.partnerId, this.id);

      TicketDataAccessLayer.delete(this);
      FleetAggregationModule.removeTicket(res.number);
    } catch (e) {
      handleError(e);
      if (!silent) throw e;
    }
  }

  async update() {
    try {
      const data: MrfiktivUpdateTicketDtoGen = {
        title: this.title,
        body: this.body,
        projectId: this.projectId,
        tags: this.tags,
        values: this.values,
        assignees: this.assignees,
        refs: this.refs,
        state: this.state,
        due: this.due
      };
      const res = await ticketService.update(this.partnerId, this.id, data);
      this.map(res);
      TicketDataAccessLayer.set(this);
      FleetAggregationModule.replaceTicket(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  async updatePartial(dto: MrfiktivUpdateTicketDtoGen) {
    try {
      const res = await ticketService.update(this.partnerId, this.id, dto);

      this.map(res);

      TicketDataAccessLayer.set(this);
      FleetAggregationModule.replaceTicket(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  async updateState(state: TicketStatusEnum) {
    try {
      const data: MrfiktivUpdateTicketDtoGen = {
        state
      };
      const res = await ticketService.update(this.partnerId, this.id, data);
      this.map(res);
      TicketDataAccessLayer.set(this);
      FleetAggregationModule.replaceTicket(this);

      if (res) {
        Vue.$log.debug(res);
        const activity = await ActivityLogModule.create({
          partnerId: this.partnerId,
          data: {
            source: {
              refType: BackendResourceEnum.TICKET,
              refId: res.id
            },
            actionType: ActionEnum.UPDATE,
            activity: ActivityTypeEnum.UPDATE_PROGRESS_STATUS,
            comment: TicketStatusEnum.CLOSED
          }
        });
        ActivityLogModule.addToList(activity);
      }
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  async createAssigneeActivity(
    activityType: ActivityTypeEnum.CREATE_ASSIGNEE | ActivityTypeEnum.DELETE_ASSIGNEE,
    newAssignees?: string[]
  ) {
    if (!newAssignees?.length) return;

    const ticketId = this.id;

    const source = {
      refType: BackendResourceEnum.TICKET,
      refId: ticketId
    };
    const partnerId = this.partnerId;

    await ActivityLogModule.createAssigneeActivity({
      partnerId,
      source,
      newAssignees,
      activityType
    });
  }
}
type ITicket = TicketBase;
const Ticket = Filter.createForClass(TicketBase);

export { ITicket, Ticket };
