import { Filter, IsFilterable } from "@/lib/filterable";
import { handleError } from "@/lib/utility/handleError";
import partnerService from "@/services/mrfiktiv/services/partnerService";
import reportService from "@/services/mrfiktiv/services/reportService";
import {
  MrfiktivBasePartnerViewModelGen,
  MrfiktivCreateActivityLogDtoGen,
  MrfiktivCreateInsuranceDtoGen,
  MrfiktivImageEditDataDtoGen,
  MrfiktivReportBodyGen,
  MrfiktivReportViewModelGen,
  MrfiktivUpdateReportDtoGen,
  MrfiktivUpdateReportImagesDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { DamageTypeEnum } from "@/store/enum/damageType.enum";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { ReportType } from "@/store/enum/reportType";
import { ReportDataAccessLayer } from "@/store/modules/access-layers/report.access-layer";
import { ActivityLogModule, ActivityTypeEnum } from "@/store/modules/activity-log.store";
import { FleetAggregationModule } from "@/store/modules/fleet-aggregation.store";
import Vue from "vue";
import { AccidentDetails, IAccidentDetails } from "./accident-details.entity";
import { Address, IAddress } from "./address.entity";
import { BaseCollection, IBaseCollection } from "./base-collection.entity";
import { ByAt, IByAt } from "./by-at.entity";
import { Contact, IContact } from "./contact.entity";
import { IImage, Image } from "./image.entity";
import { ILeasingDetails, LeasingDetails } from "./leasing.entity";
import { IPoliceDetails, PoliceDetails } from "./police.entity";
import { IPreExistingDamageDetails, PreExistingDamageDetails } from "./pre-existing-damage.entity";
import { IRegistration, Registration } from "./registration.entity";
import { RegistrationResultsEntity } from "./registrationResultsEntity";
import { IShortUser, ShortUser } from "./short-user.entity";
import { ITimestampDocument, TimestampDocument } from "./timestamp.entity";
import { IWitness, Witness } from "./witness.entity";
@IsFilterable
class ReportBase implements MrfiktivReportViewModelGen {
  /**
   * @inheritdoc
   */
  partnerId: string;

  /**
   * @inheritdoc
   */
  customerName: string;

  /**
   * @inheritdoc
   */
  firstName?: string;

  /**
   * @inheritdoc
   */
  lastName?: string;

  /**
   * @inheritdoc
   */
  isCompany?: boolean;

  /**
   * @inheritdoc
   */
  companyName?: string;

  /**
   * @inheritdoc
   */

  isTaxDeductible?: boolean;

  /**
   * @inheritdoc
   */

  taxnumber?: string;

  /**
   * @inheritdoc
   */
  insurances?: MrfiktivCreateInsuranceDtoGen[];

  /**
   * @inheritdoc
   */
  externalId?: string;

  /**
   * @inheritdoc
   */
  numberplate: string;

  /**
   * @inheritdoc
   */
  customerContact: IContact;

  /**
   * @inheritdoc
   */
  customerAddress: IAddress;

  /**
   * @inheritdoc
   */
  message: string;

  /**
   * @inheritdoc
   */
  registrationResults: IRegistration;

  /**
   * @inheritdoc
   */
  timestamps: ITimestampDocument;

  /**
   * @inheritdoc
   */
  timestamp: ITimestampDocument;

  /**
   * @inheritdoc
   */
  datePreference: string;

  /**
   * @inheritdoc
   */
  images: IBaseCollection;

  /**
   * @inheritdoc
   */
  attachment: IImage[];

  /**
   * @inheritdoc
   */
  damage: DamageTypeEnum[];

  /**
   * @inheritdoc
   */
  marketingOptIn: boolean;

  /**
   * @inheritdoc
   */
  progressStatus: ProgressStatusEnum;

  /**
   * @inheritdoc
   */
  reportType?: ReportType;

  /**
   * @inheritdoc
   */
  witness?: IWitness[];

  /**
   * @inheritdoc
   */
  accidentDetails?: IAccidentDetails;

  /**
   * @inheritdoc
   */
  policeDetails?: IPoliceDetails;

  /**
   * @inheritdoc
   */
  leasing?: ILeasingDetails;

  /**
   * @inheritdoc
   */
  preExistingDamageDetails?: IPreExistingDamageDetails;

  /**
   * @inheritdoc
   */
  body?: MrfiktivReportBodyGen[];

  /**
   * @inheritdoc
   */
  summary?: string;

  /**
   * @inheritdoc
   */
  summaryId?: string;

  /**
   * @inheritdoc
   */
  _id: string;

  /**
   * @inheritdoc
   */
  id: string;

  /**
   * @inheritdoc
   */
  vehicleId?: string;

  /**
   * @inheritdoc
   */
  userId?: string;

  /**
   * @inheritdoc
   */
  user?: IShortUser;

  /**
   * @inheritdoc
   */
  assignees: string[];

  /**
   * @inheritdoc
   */
  assigneesDetails: IShortUser[];

  /**
   * @inheritdoc
   */
  forwarded?: IByAt;

  /**
   * @inheritdoc
   */
  distributor?: MrfiktivBasePartnerViewModelGen | undefined;

  constructor(report?: Partial<MrfiktivReportViewModelGen>) {
    this._id = report?.id ?? report?._id ?? "";
    this.id = this._id;
    this.partnerId = report?.partnerId ?? "";
    this.customerName = report?.customerName ?? "";
    this.firstName = report?.firstName;
    this.lastName = report?.lastName;
    this.isCompany = report?.isCompany;
    this.companyName = report?.companyName;
    this.isTaxDeductible = report?.isTaxDeductible;
    this.taxnumber = report?.taxnumber;
    this.insurances = report?.insurances ?? [];
    this.externalId = report?.externalId;
    this.numberplate = report?.numberplate ?? "";
    this.customerContact = (report?.customerContact ? new Contact(report?.customerContact) : undefined) as IContact;
    this.customerAddress = (report?.customerAddress ? new Address(report?.customerAddress) : undefined) as IAddress;
    this.message = report?.message ?? "";
    this.registrationResults = new Registration(report?.registrationResults);
    this.datePreference = report?.datePreference ?? "";
    this.images = new BaseCollection(report?.images);
    this.attachment = (report?.attachment ?? []).map(r => new Image(r));
    this.damage = (report?.damage ?? []).map(r => r as DamageTypeEnum);
    this.marketingOptIn = report?.marketingOptIn ?? false;
    this.progressStatus = (report?.progressStatus as ProgressStatusEnum) ?? ProgressStatusEnum.IN_PROGRESS;
    this.reportType = report?.reportType as ReportType;
    this.witness = (report?.witness ?? []).map(r => new Witness(r));
    this.accidentDetails = report?.accidentDetails ? new AccidentDetails(report?.accidentDetails) : undefined;
    this.policeDetails = report?.policeDetails ? new PoliceDetails(report?.policeDetails) : undefined;
    this.leasing = report?.leasing ? new LeasingDetails(report?.leasing) : undefined;
    this.preExistingDamageDetails = report?.preExistingDamageDetails
      ? new PreExistingDamageDetails(report?.preExistingDamageDetails)
      : undefined;
    this.body = (report?.body ?? []).map(r => r);
    this.vehicleId = report?.vehicleId;
    this.userId = report?.userId;
    this.user = report?.user ? new ShortUser(report?.user) : undefined;
    this.assignees = report?.assignees ?? [];
    this.assigneesDetails = (report?.assigneesDetails ?? []).map(r => new ShortUser(r));
    this.forwarded = report?.forwarded ? new ByAt(report?.forwarded) : undefined;
    this.distributor = report?.distributor;
    this.summary = report?.summary;
    this.summaryId = report?.summaryId;
    this.timestamps = new TimestampDocument(report?.timestamps);

    this.timestamp = new TimestampDocument(report?.timestamp);
  }

  private map(report?: Partial<MrfiktivReportViewModelGen>) {
    const id = report?.id ?? report?._id;
    if (id) {
      this._id = id;
      this.id = id;
    }
    this.partnerId = report?.partnerId ?? "";
    this.customerName = report?.customerName ?? "";
    this.firstName = report?.firstName;
    this.lastName = report?.lastName;
    this.companyName = report?.companyName;
    this.taxnumber = report?.taxnumber;
    this.insurances = report?.insurances;
    this.externalId = report?.externalId;
    this.numberplate = report?.numberplate ?? "";
    this.customerContact = (report?.customerContact ? new Contact(report?.customerContact) : undefined) as IContact;
    this.customerAddress = (report?.customerAddress ? new Address(report?.customerAddress) : undefined) as IAddress;
    this.message = report?.message ?? "";
    this.registrationResults = new Registration(report?.registrationResults);
    this.datePreference = report?.datePreference ?? "";
    this.images = new BaseCollection(report?.images);
    this.attachment = (report?.attachment ?? []).map(r => new Image(r));
    this.damage = (report?.damage ?? []).map(r => r as DamageTypeEnum);
    this.marketingOptIn = report?.marketingOptIn ?? false;
    this.progressStatus = report?.progressStatus as ProgressStatusEnum;
    this.reportType = report?.reportType as ReportType;
    this.witness = (report?.witness ?? []).map(r => new Witness(r));
    this.accidentDetails = report?.accidentDetails ? new AccidentDetails(report?.accidentDetails) : undefined;
    this.policeDetails = report?.policeDetails ? new PoliceDetails(report?.policeDetails) : undefined;
    this.leasing = report?.leasing ? new LeasingDetails(report?.leasing) : undefined;
    this.preExistingDamageDetails = report?.preExistingDamageDetails
      ? new PreExistingDamageDetails(report?.preExistingDamageDetails)
      : undefined;
    this.body = (report?.body ?? []).map(r => r);
    this.vehicleId = report?.vehicleId;
    this.userId = report?.userId;
    this.user = new ShortUser(report?.user);
    this.assignees = report?.assignees ?? [];
    this.assigneesDetails = (report?.assigneesDetails ?? []).map(r => new ShortUser(r));
    this.forwarded = new ByAt(report?.forwarded);
    this.timestamps = new TimestampDocument(report?.timestamps);
    this.timestamp = new TimestampDocument(report?.timestamp);
    this.isCompany = report?.isCompany;
    this.isTaxDeductible = report?.isTaxDeductible;
    this.summary = report?.summary;
    this.summaryId = report?.summaryId;
  }

  async fetch() {
    try {
      const report = await partnerService.getReportById(this.partnerId, this.id);

      this.map(report);

      ReportDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  async updateProgressStatus(progressStatus: ProgressStatusEnum) {
    Vue.$log.info("updating progress status to " + progressStatus);
    try {
      const res = await partnerService.updateProgressStatus(this.partnerId, this.id, { progressStatus });

      this.map(res);
    } catch (error) {
      Vue.$log.error(error);
    }

    Vue.$log.info("new progress status " + this.progressStatus);

    ReportDataAccessLayer.set(this);

    try {
      const data: MrfiktivCreateActivityLogDtoGen = {
        source: {
          refType: BackendResourceEnum.REPORT,
          refId: this.id
        },
        actionType: ActionEnum.UPDATE,
        activity: ActivityTypeEnum.UPDATE_PROGRESS_STATUS,
        comment: this.progressStatus
      };
      const activity = await ActivityLogModule.create({ partnerId: this.partnerId, data: data });
      ActivityLogModule.addToList(activity);
    } catch (error) {
      Vue.$log.error(error);
      Vue.$log.error("Error Saving Actiity");
    }

    FleetAggregationModule.replaceReport(this);
  }

  async updateReport(updateDto: MrfiktivUpdateReportDtoGen) {
    const updated = await reportService.updateReport(this.partnerId, this.id, updateDto);

    this.map(updated);

    ReportDataAccessLayer.set(this);

    FleetAggregationModule.replaceReport(this);

    return this;
  }

  async updateReportImages(updateDto: MrfiktivUpdateReportImagesDtoGen) {
    const updated = await reportService.updateReportImages(this.partnerId, this.id, updateDto);

    this.map(updated);

    ReportDataAccessLayer.set(this);

    return updated;
  }

  async editReportImages(imageId: string, data: MrfiktivImageEditDataDtoGen) {
    const updated = await reportService.editReportImage(this.partnerId, this.id, imageId, data);

    this.map(updated);

    ReportDataAccessLayer.set(this);

    return updated;
  }

  async create() {
    try {
      const created = await reportService.createAsPartner(this.partnerId, {
        accidentDetails: this.accidentDetails,
        assignees: this.assignees,
        body: this.body,
        companyName: this.companyName,
        customerAddress:
          this.customerAddress?.city ||
          this.customerAddress?.state ||
          this.customerAddress?.state ||
          this.customerAddress?.street
            ? this.customerAddress
            : undefined,
        customerContact: this.customerContact?.email || this.customerContact?.phone ? this.customerContact : undefined,
        customerName: this.customerName ? this.customerName : undefined,
        damage: this.damage,
        datePreference: this.datePreference ? this.datePreference : undefined,
        externalId: this.externalId,
        leasing: this.leasing,
        marketingOptIn: this.marketingOptIn,
        message: this.message,
        numberplate: this.numberplate,
        policeDetails: this.policeDetails,
        preExistingDamageDetails: this.preExistingDamageDetails,
        registrationResults: this.registrationResults,
        reportType: this.reportType,
        vehicleId: this.vehicleId,
        images: this.images.imageCollectionDto
      });

      this.map(created);

      ReportDataAccessLayer.set(this);
      FleetAggregationModule.parseReports([{ ...created, id: created._id }]);
    } catch (error) {
      handleError(error);
    }

    return this;
  }

  async addRegistrationResults(registrationResult: RegistrationResultsEntity) {
    try {
      const response = await partnerService.addRegistrationDetailsForReport(
        this.partnerId,
        this.id,
        registrationResult
      );

      this.map(response);
    } catch (error) {
      handleError(error);
    }

    return this;
  }
}

type IReport = ReportBase;
const Report = Filter.createForClass(ReportBase);

export { IReport, Report };
