import { NotFoundException } from "@/lib/exceptions/http";
import { generateCode } from "@/lib/utility/code-generator";
import { FormEntity } from "@/models/formEntity";
import { PartnerContactEntity } from "@/models/partnerContactEntity";
import { RatingEntity } from "@/models/ratingEntitity";
import { RegistrationResultsEntity } from "@/models/registrationResultsEntity";
import { IReport, Report } from "@/models/report.entity";
import i18n from "@/plugins/I18nPlugin";
import { MrfiktivCreatePartnerDtoGen, MrfiktivPartnerViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import sharedPartnerService from "@/services/shared/partnerService";
import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import partnerService from "../../services/mrfiktiv/services/partnerService";
import { PartnerTypeEnum } from "../enum/partner/partner.type.enum";
import { IPartnerUpdateDto } from "../interface/partner-update.dto.interface";
import { IPartnerRessourceStoreInterface } from "../interface/partner.ressource.store.interface";
import { ReportDataAccessLayer } from "./access-layers/report.access-layer";
@Module({
  dynamic: true,
  namespaced: true,
  name: "partner",
  store
})
export class Partner extends VuexModule {
  partnerContact = {} as PartnerContactEntity;

  partner: MrfiktivPartnerViewModelGen = {} as MrfiktivPartnerViewModelGen;
  partners: MrfiktivPartnerViewModelGen[] = [];
  partnersMap: Map<string, MrfiktivPartnerViewModelGen> = new Map();

  analyticsPartners: MrfiktivPartnerViewModelGen[] = [];

  forms: FormEntity[] = [];
  form: FormEntity = {} as FormEntity;
  formExportFile = "";
  reports: IReport[] = [];
  private _report: IReport = {} as IReport;
  reportExportFile = "";
  loading = true;
  partnersLoading = true;
  reportsLoading = true;
  formsLoading = true;
  progressUpdateLoading = false;
  isRatingSubmissionEnabled = false;

  get report() {
    return this._report;
  }

  get isTrain() {
    return this.partner?.partnerType === PartnerTypeEnum.TRAIN;
  }

  /**
   * A unique id for tracking the checkout
   */
  private _transactionId = generateCode();

  /**
   * @returns the transaction id
   */
  get transactionId() {
    return this._transactionId;
  }

  @Mutation
  _mutateTransactionId(transactionId: string) {
    this._transactionId = transactionId;
  }

  @Action
  async getPartnerContact() {
    return this.partnerContact;
  }

  @Action
  resetPartners() {
    this.context.commit("setPartners", []);
    this.context.commit("setPartner", undefined);
  }

  @Mutation
  setPartnerContact(partnerContact: PartnerContactEntity) {
    this.partnerContact = partnerContact;
  }

  @Action
  async sendContact() {
    return await partnerService.contact(this.partnerContact, this.partner.companyUsername);
  }

  @Action
  async getPartnerByName(partnerName: string): Promise<MrfiktivPartnerViewModelGen> {
    if (!partnerName) {
      Vue.$toast.error("Fehler beim Laden des Partners.");
      throw new Error("Fehler beim Laden des Partners.");
    }

    try {
      const response = await partnerService.getPartnerByName(partnerName);
      response.data._id = response.data.id;
      const partnerData = response.data as MrfiktivPartnerViewModelGen;
      this.context.commit("setPartner", partnerData);

      return partnerData;
    } catch (e) {
      if (e instanceof NotFoundException) {
        Vue.$toast.error(`Partner ${partnerName} nicht gefunden.`);
      }
      throw e;
    }
  }

  @Action
  async getPartnerByHost(host: string): Promise<MrfiktivPartnerViewModelGen> {
    if (!host) {
      Vue.$toast.error("Fehler beim Laden des Partners.");
      throw new Error("Fehler beim Laden des Partners.");
    }

    try {
      const response = await partnerService.getPartnerByHost(host);
      const partnerData = response.data as MrfiktivPartnerViewModelGen;
      this.context.commit("setPartner", partnerData);

      return partnerData;
    } catch (e) {
      Vue.$toast.error(`Partner ${host} nicht gefunden.`);
      throw e;
    }
  }

  @Action
  async getPartnerById(partnerId: string) {
    try {
      const response = await partnerService.getPartnerById(partnerId);
      const partnerData = response.data as MrfiktivPartnerViewModelGen;
      this.context.commit("setPartner", partnerData);

      return partnerData;
    } catch (e) {
      Vue.$toast.error(`Partner ${partnerId} nicht gefunden.`);
      throw e;
    }
  }

  @Action
  async createPartner(createPartnerDto: MrfiktivCreatePartnerDtoGen): Promise<MrfiktivPartnerViewModelGen> {
    let createdPartner: MrfiktivPartnerViewModelGen = undefined as any;
    try {
      createdPartner = await partnerService.create(createPartnerDto);
      await this.getPartners();
    } catch (error) {
      Vue.$log.error(error);
    }
    return createdPartner;
  }

  @Action
  async updatePartner(partnerUpdateDto: IPartnerUpdateDto): Promise<MrfiktivPartnerViewModelGen> {
    let updatetPartner = undefined;
    try {
      const response = await partnerService.updatePartner(partnerUpdateDto);
      await this.getPartners();
      const partnerData = response.data as MrfiktivPartnerViewModelGen;
      this.context.commit("setPartner", partnerData);
      updatetPartner = response.data;
    } catch (e) {
      Vue.$log.error(e);
    }

    return updatetPartner;
  }

  @Action
  async deletePartner(partnerId: string) {
    try {
      const response = await partnerService.deletePartner(partnerId);
      this.context.commit("setPartner", {} as MrfiktivPartnerViewModelGen);
      Vue.$log.info(response);
      Vue.$toast.success("Der Parnter wurde erfolgreich gelöscht.");
    } catch (e) {
      Vue.$toast.error(`Partner ${partnerId} konnte nicht gelöscht werden.`);
    }
  }

  @Mutation
  setPartner(partner: MrfiktivPartnerViewModelGen) {
    this.partner = partner;
    this.loading = false;
  }

  @Action
  async getPartners(ids?: string[]) {
    let partners;
    if (!ids) {
      partners = await partnerService.getAllPartners();
    } else {
      Vue.$log.warn(`Endpoint accepts oly 80 items. requested ${ids.length}. Sending multiple requests.`);
      // the endpoint only accepts a limited amount of ids at once so multiple request might have to be sent depending on the amount of requested items
      while (ids.length > 0) {
        const partialIds = ids.splice(0, 80);
        partners = [...(partners || []), ...((await sharedPartnerService.getAll({ ids: partialIds })) || [])];
      }
    }

    this.context.commit("setPartners", partners);
  }

  @Mutation
  setPartners(partners: MrfiktivPartnerViewModelGen[]) {
    this.partners = partners;

    this.partnersMap = new Map();
    this.partners.forEach(p => this.partnersMap.set(p._id || p.id, p));

    this.partnersLoading = false;
  }

  @Action
  async getAnalyticsPartners(ids: string[]) {
    const partners = await sharedPartnerService.getAnalyticsPartnersByIds({ ids });
    this.context.commit("setAnalyticsPartners", partners);
  }

  @Mutation
  setAnalyticsPartners(analyticsPartners: MrfiktivPartnerViewModelGen[]) {
    this.analyticsPartners = analyticsPartners;
  }

  @Action
  async getAllFormsForPartner() {
    try {
      const forms = await partnerService.getAllFormsForPartner(this.partner._id);

      forms.sort(function(a, b) {
        if (a.timestamps?.created && b.timestamps?.created) {
          return a.timestamps.created > b.timestamps.created ? -1 : a.timestamps.created > b.timestamps.created ? 1 : 0;
        }
        return -1;
      });

      this.context.commit("_mutateForms", forms);
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  @Mutation
  async _mutateForms(forms: any[]) {
    this.forms = forms;
    this.formsLoading = false;
  }

  @Action
  setForm(form: any) {
    this.context.commit("updateForm", form);
  }

  @Action
  async getFormByIdForPartner(formId: string) {
    try {
      const form = await partnerService.getFormById(this.partner._id, formId);
      this.context.commit("updateForm", form);
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  @Action
  async getFormByPartnerIdAndReportId(dto: IPartnerRessourceStoreInterface) {
    try {
      const form = await partnerService.getFormById(dto.partnerId, dto.documentId);
      this.context.commit("updateForm", form);
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  @Mutation
  updateForm(form: FormEntity) {
    this.form = form;
  }

  @Action
  async getAllReportsForPartner() {
    try {
      const reports = await partnerService.getAllReportsForPartner(this.partner._id);

      // FIXME: Make this more reusable
      reports.sort(function(a, b) {
        return a.timestamps.created > b.timestamps.created ? -1 : a.timestamps.created > b.timestamps.created ? 1 : 0;
      });

      this.context.commit("_mutateReports", reports);
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  @Action
  setReport(report: any) {
    this.context.commit("mutateUpdateReport", report);
  }

  @Mutation
  _mutateReports(reports: any[]) {
    this.reports = reports;
    this.reportsLoading = false;
  }

  @Action
  async getReportByIdForPartner(reportId: string) {
    const report = await partnerService.getReportById(this.partner._id, reportId);
    this.context.commit("mutateUpdateReport", report);

    return report;
  }

  @Action
  async getReportByPartnerIdAndReportId(dto: IPartnerRessourceStoreInterface) {
    const report = await partnerService.getReportById(dto.partnerId, dto.documentId);
    this.context.commit("mutateUpdateReport", report);

    return report;
  }

  @Mutation
  mutateUpdateReport(report: IReport) {
    this._report = new Report(report);
    if (report) {
      ReportDataAccessLayer.set(this._report);
    }
  }

  @Action
  setFormRegistration(registration: RegistrationResultsEntity) {
    this.context.commit("updateFormRegistration", registration);
  }

  @Mutation
  updateFormRegistration(registration: RegistrationResultsEntity) {
    this.form.registrationResults = registration;
  }

  @Action
  async getExportForReportById(data: { reportId: string; partnerId?: string; type: "ksr" | "dealerdesk" }) {
    const response = await partnerService.getExportForReportById(
      data.partnerId || this.partner._id,
      data.reportId,
      data.type
    );
    const reportExportFile = response.data.url;
    this.context.commit("setReportExportFile", reportExportFile);
  }

  @Mutation
  setReportExportFile(reportExportFile: string) {
    this.reportExportFile = reportExportFile;
  }

  @Action
  async getExportForFormById(formId: string) {
    try {
      const reponse = await partnerService.getExportForFormById(this.partner._id, formId, "ksr");
      const formExportFile = reponse.data.url;
      this.context.commit("setFormExportFile", formExportFile);
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  @Mutation
  setFormExportFile(formExportFile: string) {
    this.formExportFile = formExportFile;
  }

  @Action
  async addRegistrationDetailsForForm(payload: any) {
    let response: FormEntity = undefined as any;
    try {
      response = await partnerService.addRegistrationDetailsForForm(
        this.partner._id,
        payload.formId,
        payload.registrationResults
      );
      this.setFormRegistration(response.registrationResults);
    } catch (error) {
      Vue.$log.error(error);
    }

    return response;
  }

  @Mutation
  _mutateProgressUpdateLoading(progressUpdateLoading: boolean) {
    this.progressUpdateLoading = progressUpdateLoading;
  }

  @Action
  async addRatingForPartner(rating: RatingEntity) {
    let response: IReport = undefined as any;
    try {
      response = await partnerService.addRatingForPartner(this.partner._id, rating);
      this.context.commit("_mutateRatingSubmissionEnabled", false);
      Vue.$log.info(response);
      Vue.$toast.success(i18n.t("modules.partner.addRatingSuccess"));
    } catch (error) {
      Vue.$log.error(error);
      Vue.$toast.error(i18n.t("modules.partner.addRatingError"));
      throw error;
    }

    return response;
  }

  @Action
  setRatingSubmissionEnabled(ratingSubmissionEnabled: boolean) {
    this.context.commit("_mutateRatingSubmissionEnabled", ratingSubmissionEnabled);
  }

  @Mutation
  _mutateRatingSubmissionEnabled(ratingSubmissionEnabled: boolean) {
    this.isRatingSubmissionEnabled = ratingSubmissionEnabled;
  }
}

export const PartnerModule = getModule(Partner);
