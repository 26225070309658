/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
export enum ReportScreenEnum {
  welcome = "welcome",
  welcomealt = "welcomealt",

  /**
   * Welcome home but with auth guard
   */
  welcomelogin = "welcomelogin",

  registration = "registration",

  damagelocator = "damagelocator",
  damagelocatortruck = "damagelocatortruck",
  damagelocatorcombined = "damagelocatorcombined",

  overviewimage = "overviewimage",
  componentimage = "componentimage",
  damagedetail = "damagedetail",
  damagedetailrequired = "damagedetailrequired",
  mileage = "mileage",
  message = "message",
  messagerequired = "messagerequired",
  contactdetails = "contactdetails",
  contactalt = "contactalt",
  simplecontact = "simplecontact",
  daterequest = "daterequest",
  closing = "closing",
  reportType = "reportType",
  witness = "witness",
  witnessNumberplate = "witnessNumberplate",
  liabilityselector = "liabilityselector",
  vinimage = "vinimage",
  tuevimage = "tuevimage",
  tireimage = "tireimage",
  wheelImage = "wheelImage",
  wheelDetailImage = "wheelDetailImage",
  wheelInsurance = "wheelInsurance",
  additionalimage = "additionalimage",
  accidentdetails = "accidentdetails",
  accidentopponents = "accidentopponents",
  police = "police",
  responsibleparty = "responsibleparty",
  driverslicense = "driverslicense",
  damageimageoptional = "damageimageoptional",
  closingalt = "closingalt",
  closingauth = "closingauth",
  closingaltauth = "closingaltauth",
  overviewimagealt = "overviewimagealt",
  overviewimageauth = "overviewimageauth",
  externalidhueffermann = "externalidhueffermann",
  closingsimple = "closingsimple",
  numberplate = "numberplate",
  damagenotice = "damagenotice",
  leasing = "leasing",
  insurance = "insurance",
  /**
   * Verkehrsrechtsschutz
   */
  motoristLegalInsurance = "motoristLegalInsurance",

  /**
   * Screen to add the pre existing damage details
   */
  preexistingdamage = "preexistingdamage",

  /**
   * repareo screens
   */
  REPAREO_HOME = "repareohome",
  REPAREO_DATE_REQUEST = "repareodaterequest",
  REPAREO_CLOSING = "repareoclosing",

  /**
   * Report control
   */
  PARTNER_FINDER = "finder",

  /**
   * used to the partner control system, will set the partner finder selected partner as receiver of the report
   */
  REPORT_CONTROL_CLOSING = "rcclosing",

  /**
   * Screen to add user and user company details
   */
  CONTACT = "contact",

  /**
   * thg screens
   */
  thghome = "thghome",
  thgzip = "thgzip",
  thgcontact = "thgcontact",
  thgcontactat = "thgcontactat",
  thgregistration = "thgregistration",
  thgregistrationat = "thgregistrationat",
  thgregistrationfront = "thgregistrationfront",
  thgpassword = "thgpassword",
  thgcustomerdetails = "thgcustomerdetails",
  thgProducts = "thgproducts",
  thgVehicleClass = "thgvehicleclass",
  thgSustainable = "thgsustainable",
  thgSustainableTrees = "thgSustainableTrees",
  thgPayoutConfiguration = "thgPayoutConfiguration",
  thgCustomerAccount = "thgCustomerAccount",
  thgCheckout = "thgcheckout",
  thgCheckoutWithHint = "thgCheckoutWithHint",

  /**
   * Displays only the "retire" option in impact selector for "niemand.kauftdeinethg.de"
   */
  thgImpactSelectorRetire = "thgretire",

  /**
   * Allows user to set the @see `PayoutOptionEnum`
   */
  thgpayoutoptionselector = "thgpayoutoptionselector",

  /**
   * @deprecated
   */
  thgimpactselector = "thgimpactselector",
  /**
   * @deprecated
   */
  thgimpactselectortrees = "thgimpactselectortrees",
  /**
   * @deprecated
   */
  thgImpactSelectorWithCustomerAccount = "thgImpactSelectorCustomerAccount",
  /**
   * @deprecated
   */
  thgImpactSelectorOnlyCustomerAccount = "thgImpactSelectorOnlyCustomerAccount",
  /**
   * @deprecated
   */
  thgImpactSelectorOnlyCustomerAccountAndPayout = "thgImpactSelectorOnlyCustomerAccountAndPayout",
  /**
   * @deprecated
   */
  thgImpactSelectorOnlyPayout = "thgImpactSelectorOnlyPayout",
  /**
   * @deprecated
   */
  thgimpactfactor = "thgimpactfactor",
  /**
   * @deprecated
   */
  thgclosingwithhint = "thgclosingwithhint",
  /**
   * @deprecated
   */
  thgYearSelection = "thgYearSelection",
  /**
   * @deprecated
   */
  thgclosing = "thgclosing"
}
